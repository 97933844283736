import React from "react";
import SurveyAnalysisContainer from "../components/SurveyAnalysisContainer";

const Analysis = () => {
  return (
    <>
      <SurveyAnalysisContainer />
    </>
  );
};

export default Analysis;
