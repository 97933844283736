import React from "react";
import HomeContainer from "../components/HomeContainer";

const KeyManagementHome = () => {
  return (
    <>
      <HomeContainer />
    </>
  );
};

export default KeyManagementHome;
