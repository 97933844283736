import KeyIcon from "@mui/icons-material/Key";

export const routes = [
  {
    path: "",
    to: "/key-management",
    name: "Keys",
    icon: <KeyIcon />,
  },
];
